export const qCompanyValidator = async (request, path, message) => {
   const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
   const digit = /^\d+$/;
   const regexName = /^[a-zA-Z ]+$/; 
   const regexUsername = /^[a-zA-Z\_-]+$/;

   if (request['nom'] !== '' && !regexName.test(request.nom)) {
    return {
       error: true,
       path: 'nom',
       message: 'Veillez entrer un nom sans caractère spéciaux',
    };
 }else if (request['username'] !== '' &&!regexUsername.test(request.username)) {
    return {
       error: true,
       path: 'username',
       message: 'Seul les caractères [_-] sont autorisé',
    };
 }else if (request['email'] !== '' &&!regexEmail.test(request.email)) {
    return {
       error: true,
       path: 'email',
       message: 'Veillez entrer un email valide',
    };
 } 



   for (let i = 0; i < Object.keys(request).length; i++) {
      const element = Object.keys(request)[i];
      if (request[element] === '' || request[element] === undefined || request[element] === null) {
         return {
            error: true,
            path: element,
            message: 'Ce champs est obligatoire',
         };
      }
   
   }

   for (let i = 0; i < Object.keys(request).length; i++) {
    const element = Object.keys(request)[i];
    if (request[element] !== '' || request[element] !== undefined || request[element] !== null) {
       
      return {
         error: false,
         path: '',
         message: '',
      };
   }
 
 }



   




};
